<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="3"
            xl="2"
          >
            <b-form-group
              label="Ano"
              label-for="user-status"
              class="-ml-1"
            >
              <v-select
                v-model="filterYear"
                :options="yearOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            xl="2"
          >
            <b-form-group
              label="Mês"
              label-for="user-status"
            >
              <v-select
                v-model="filterMonth"
                :options="monthOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="5"
            xl="4"
          >
            <label for="institution-id">Instituição</label>
            <v-select
              id="institution-id"
              v-model="institutionId"
              name="institution-id"
              :options="institutionOptions"
              label="label"
              item-value="value"
              item-text="label"
              @input="fetchWorkplaces"
            >
              <span slot="no-options">Nenhuma instituição encontrada.</span>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="4"
            xl="4"
          >
            <label for="workplace-id">Unidade</label>
            <v-select
              id="workplace-id"
              v-model="workplaceId"
              name="workplace-id"
              :options="workplaceOptions"
              label="label"
              item-value="value"
              item-text="label"
              :disabled="!institutionId"
            >
              <span slot="no-options">Nenhuma unidade encontrada.</span>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="8"
            xl="6"
          >
            <div class="d-flex w-100">
              <div class="flex-grow-1">
                <label for="physician-user-id">Médicos</label>
                <v-select
                  id="physician-user-id"
                  v-model="physicianUserId"
                  :filter="filterPhysician"
                  name="physician-user-id"
                  :options="physicianUserOptions"
                  label="label"
                >
                  <template #option="{ value: { name, email, crm, cpf } }">
                    <div class="font-weight-bold">
                      {{ name }}
                    </div>
                    <div>
                      <span>{{ email }}</span>
                    </div>
                    <div>
                      <span class="physician-label">CPF</span>
                      <span class="font-weight-bold"> {{ maskHiddenCpf(cpf) }}</span>
                      <span class="physician-label ml-1">CRM</span>
                      <span class="font-weight-bold"> {{ crm }}</span>
                    </div>
                  </template>
                  <span slot="no-options">Nenhum médico encontrado.</span>
                </v-select>
              </div>
              <div class="pt-2 ml-1 mr-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mr-1"
                  @click="loadReport"
                >
                  Buscar
                  <feather-icon
                    icon="SearchIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon"
                  :disabled="reports.length === 0"
                  @click="downloadReport"
                >
                  Exportar
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- institutions -->
            <div
              v-if="reports.length === 0"
              class="m-5"
            >
              <h4 class="text-center w-100">
                Não há registros
              </h4>
            </div>
            <app-collapse
              accordion
              type="border"
            >
              <app-collapse-item
                v-for="report in reports"
                :key="report.name"
                :title="report.name"
              >
                <template v-slot:header>
                  <div class="d-flex justify-content-between w-100">
                    <h4>{{ report.name }}</h4>
                    <div class="text-right">
                      <div>
                        <span class="text-muted">Limite</span>
                        <span class="collapse-title"> {{ currency(report.limit) }}</span>
                      </div>
                      <div>
                        <span class="text-muted">Lançamentos</span>
                        <span class="collapse-title"> {{ currency(report.amount_without_discount) }}</span>
                      </div>
                      <div>
                        <span class="text-muted">Antecipados</span>
                        <span class="collapse-title"> {{ currency(report.amount_requested) }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- Physician -->
                <h6 class="text-muted">
                  Unidades
                </h6>
                <app-collapse accordion>
                  <app-collapse-item
                    v-for="workplace in report.workplace"
                    :key="workplace.name"
                    :title="workplace.name"
                    class="panel-workplace"
                  >
                    <template v-slot:header>
                      <div class="d-flex justify-content-between w-100">
                        <h4>{{ workplace.name }}</h4>
                        <div class="text-right">
                          <div>
                            <span class="text-muted">Valor</span>
                            <span class="collapse-title"> {{ currency(workplace.amount_without_discount) }}</span>
                          </div>
                          <div>
                            <span class="text-muted">Antecipado</span>
                            <span class="collapse-title"> {{ currency(workplace.amount_requested) }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <h6 class="text-muted">
                      Serviços
                    </h6>
                    <b-list-group flush>
                      <b-list-group-item
                        v-for="(duty, index) in workplace.items"
                        :key="duty.id"
                        :title="duty.id"
                        class="physician-group-item"
                      >
                        <div class="d-flex justify-content-between w-100">
                          <div class="text-right">
                            <div class="d-flex duty-dates">
                              <h1>{{ index + 1 }}</h1>
                              <div>
                                <div class="text-right duty-date">
                                  <span class="text-muted">Início</span>
                                  <span class="collapse-title"> {{ formatDate(duty.start_date) }}</span>
                                </div>
                                <div class="text-right duty-date">
                                  <span class="text-muted">Fim</span>
                                  <span class="collapse-title"> {{ formatDate(duty.end_date) }}</span>
                                </div>
                              </div>
                              <div>
                                <div class="text-left duty-date ml-4">
                                  <span class="text-muted">Médico</span>
                                  <span class="collapse-title"> {{ duty.physician_name || '-' }}</span>
                                </div>
                                <div class="text-left duty-date ml-4">
                                  <span class="text-muted">CPF</span>
                                  <span class="collapse-title"> {{ duty.physician_cpf ? maskHiddenCpf(duty.physician_cpf) : '-' }}</span>
                                </div>
                              </div>
                              <div>
                                <div class="text-left duty-date ml-4">
                                  <span class="text-muted">Tipo</span>
                                  <span class="collapse-title"> {{ duty.person_type }}</span>
                                </div>
                              </div>
                              <div v-if="duty.physician_company_cnpj">
                                <div class="text-left duty-date ml-4">
                                  <span class="text-muted">Empresa</span>
                                  <span class="collapse-title"> {{ duty.physician_company_name }}</span>
                                </div>
                                <div class="text-left duty-date ml-4">
                                  <span class="text-muted">CNPJ</span>
                                  <span class="collapse-title"> {{ formatCnpj(duty.physician_company_cnpj ) }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-right">
                            <div>
                              <span class="text-muted">Valor</span>
                              <span class="collapse-title"> {{ currency(duty.amount_without_discount) }}</span>
                            </div>
                            <div>
                              <span class="text-muted">Status</span>
                              <span class="collapse-title"> {{ duty.status }}</span>
                            </div>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </app-collapse-item>
                </app-collapse>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import { format, parseISO, subYears } from 'date-fns'
import Fuse from 'fuse.js'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol,
  BListGroup, BListGroupItem,
  BFormGroup, BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { formatCnpj, maskCnpj, maskHiddenCpf } from '@core/utils/utils'
import reportStoreModule from '../reportStoreModule'
import useReport from './useReport'

export default {
  directives: {
    Ripple,
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    vSelect,
    BButton,
  },
  methods: {
    formatCnpj,
    maskHiddenCpf,
  },
  setup() {
    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    const currency = value => formatter.format(value / 100)
    const formatDate = value => format(parseISO(value), 'dd/MM/yyyy HH:mm')
    const reports = ref([])
    const filterMonth = ref(format(new Date(), 'MM'))
    const filterYear = ref(format(new Date(), 'yyyy'))
    const physicianUserId = ref('')
    const institutionId = ref('')
    const workplaceId = ref('')
    const physicianUserOptions = ref([])
    const institutionOptions = ref([])
    const workplaceOptions = ref([])
    const institution = ref({
      address: {},
      duties: {
        6: '',
        12: '',
        24: '',
      },
    })
    const yearOptions = (new Array(4)).fill(0).map((_, i) => {
      const year = format(subYears(new Date(), i), 'yyyy')
      return { label: year, value: year }
    }).filter(({ value }) => value > '2020')
    const monthOptions = [
      { label: 'Janeiro', value: '01' },
      { label: 'Fevereiro', value: '02' },
      { label: 'Março', value: '03' },
      { label: 'Abril', value: '04' },
      { label: 'Maio', value: '05' },
      { label: 'Junho', value: '06' },
      { label: 'Julho', value: '07' },
      { label: 'Agosto', value: '08' },
      { label: 'Setembro', value: '09' },
      { label: 'Outubro', value: '10' },
      { label: 'Novembro', value: '11' },
      { label: 'Dezembro', value: '12' },
    ]

    const getReportParams = () => ({
      date: `${filterYear.value}-${filterMonth.value}`,
      institution: institutionId.value ? institutionId.value.value : '',
      workplace: workplaceId.value ? workplaceId.value.value : '',
      user: physicianUserId.value ? physicianUserId.value.value.user_id : '',
    })

    const loadReport = () => {
      store.dispatch('app-reports/fetchReportContracts', getReportParams())
        .then(response => {
          reports.value = response.data.data.institution ?? []
        })
    }
    loadReport()

    const downloadReport = () => {
      const params = getReportParams()
      store.dispatch('app-reports/fetchReportContractsExport', params)
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Lançamentos_${params.date.replace('-', '_')}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }

    store.dispatch('app-reports/fetchPhysicians', { perPage: 99999999 })
      .then(response => {
        physicianUserOptions.value = response.data.data.map(physician => ({
          value: physician,
          label: `${physician.name} | ${maskHiddenCpf(physician.cpf)}`,
        }))
      })

    store.dispatch('app-reports/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })

    const fetchWorkplaces = () => {
      if (institutionId.value) {
        store.dispatch('app-reports/fetchWorkplaceByInstitution', { institution_id: institutionId.value.value })
          .then(response => {
            workplaceOptions.value = response.data.data.map(w => ({
              value: w.id,
              label: w.name,
            }))
          })
      } else {
        workplaceOptions.value = []
        workplaceId.value = null
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    function filterPhysician(options, search) {
      const fuse = new Fuse(options, {
        keys: ['value.name', 'value.cpf', 'value.crm', 'value.email'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    }

    const {
      tableColumns,
      perPage,
      currentPage,
      totalInstitutions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refInstitutionListTable,

      refetchData,
      fetchInstitutions,

      updateInstitution,
      searchQuery,
      sortBy,
      phoneMask,
    } = useReport()

    return {
      currency,
      formatDate,
      reports,
      workplaceId,
      workplaceOptions,
      fetchWorkplaces,

      updateInstitution,
      searchQuery,
      fetchInstitutions,
      perfectScrollbarSettings,
      sortBy,
      loadReport,

      mqShallShowLeftSidebar,
      physicianUserId,
      institutionId,
      tableColumns,
      perPage,
      currentPage,
      totalInstitutions,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refInstitutionListTable,
      refetchData,
      institution,
      phoneMask,
      physicianUserOptions,
      institutionOptions,
      downloadReport,
      filterPhysician,
      yearOptions,
      monthOptions,
      filterYear,
      filterMonth,
    }
  },
}
</script>

<style lang="scss" scoped>
.text-muted {
  color: #898993!important;
}
.duty-date, .duty-date .collapse-title {
  font-size: 12px;
}
.physician-group-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
  .collapse-title, .text-right {
    font-size: 12px;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.panel-physician {
  background-color: rgb(248, 248, 248);
}

label {
  margin-top: 0.2857rem!important;
}

.collapse-border .card .card-header {
  padding-top: .5rem;
  padding-bottom: .5rem;
  h4 {
    margin-bottom: 0;
  }
  div.d-flex {
    align-items: center;
  }
}

.duty-dates {
  align-items: center;
  h1 {
    color: #898993;
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.physician-label {
  font-size: 12px;
}
</style>
